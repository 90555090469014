@import "src/styles/helpers";

.customProcessCard {
    border: 1px solid;
    border-radius: 8px;
    @include setProperty(border-color, var(--dark-line-shape), #e0dcdc); // TODO: Update color
    @include setProperty(background-color, var(--secondary-800), var(--light-bg));
    @include setProperty(background, radial-gradient(at 25% 200%, rgba(129,222,207, 0.08) 0%, rgba(25,25,25, 1) 60%), var(--light-bg));
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;

    &__infoPart {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        &__step {
            @include setProperty(color, var(--primary-500), var(--primary-900));
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }

        &__title {
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
            font-family: 'KharkivTone', sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
        }

        &__description {
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
        height: 100%;
        }
    }

    &__contentPart {
        width: 100%;
        height: 100%;
    }

    @include media(mobile) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;

        &__infoPart {
            padding: 48px 32px 18px 32px;
        }
    }

    @include media(portrait) {
        &__infoPart {
            padding: 48px 32px 18px 32px;
        }
    }

    @include media(landscape) {
        &__infoPart {
            padding: 48px 32px 18px 32px;
        }
    }

    @include media(desktopAll) {
        &__infoPart {
            padding: 48px;

            &__step {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
            }

            &__description {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.183px;
            }
        }
    }

    @include media(desktopBig) {
        &__infoPart {
            padding: 48px;

            &__step {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }

            &__description {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.183px;
            }
        }
    }

    @include media(desktopHuge) {
        &__infoPart {
            padding: 48px;

            &__step {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }

            &__description {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.183px;
            }
        }
    }
}